<script>
import PrototypeSvg from "../assets/img/PrototypeSvg.vue";

export default {
  name: 'MainPopup',
  components: {PrototypeSvg}
}

</script>

<template>
  <div class="main-popup">
    <div class="text">
      <svg class="bg-mob" preserveAspectRatio="none" width="320" height="480" viewBox="0 0 320 480" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_7_481" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="320" height="480">
          <rect width="320" height="480" rx="40" fill="#101018"/>
        </mask>
        <g mask="url(#mask0_7_481)">
          <rect width="320" height="480" rx="40" fill="#101018"/>
          <rect width="320" height="480" rx="40" fill="url(#paint0_linear_7_481)" fill-opacity="0.08"/>
          <rect x="0.5" y="0.5" width="319" height="479" rx="39.5" stroke="url(#paint1_linear_7_481)"
                stroke-opacity="0.17"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_7_481" x1="320" y1="2.23112e-05" x2="-91.4977" y2="98.7595"
                          gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_7_481" x1="160" y1="0" x2="160" y2="480" gradientUnits="userSpaceOnUse">
            <stop stop-color="#85BDFF"/>
            <stop offset="1" stop-color="#7FB8FB" stop-opacity="0"/>
          </linearGradient>
        </defs>
      </svg>
      <div class="logo">
        <svg width="256" height="60" viewBox="0 0 256 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M3.99465 0.1707C1.72508 1.02774 1.41584 4.50341 0.797342 11.4548L0.313203 16.8961C0.133702 18.9135 -0.00163946 20.4346 1.5006e-05 21.6745C-0.00170659 22.9768 0.144821 23.9694 0.545405 24.8975C1.32485 26.7033 2.94011 27.9727 6.17062 30.5116L14.3789 36.9624L12.8911 38.3856C10.6523 40.5272 9.53297 41.598 9.08306 43.0103C8.63314 44.4227 8.92609 45.9462 9.51198 48.9932L9.84131 50.7059C10.9127 56.2776 11.4483 59.0635 13.4768 59.8243C15.5052 60.5852 17.7317 58.8354 22.1847 55.3358L29.9999 49.1938L37.8151 55.3358C42.2681 58.8354 44.4946 60.5852 46.523 59.8243C48.5514 59.0635 49.0871 56.2776 50.1585 50.7059L50.4878 48.9932C51.0737 45.9462 51.3666 44.4227 50.9167 43.0103C50.4668 41.598 49.3474 40.5272 47.1087 38.3856L45.595 36.9376L53.8292 30.4664C57.0597 27.9276 58.6749 26.6581 59.4544 24.8523C60.2338 23.0466 60.0514 20.9964 59.6866 16.8961L59.2024 11.4548C58.5839 4.50341 58.2747 1.02774 56.0051 0.1707C54.7899 -0.288179 53.5037 0.189025 51.798 1.47076C50.3065 2.5813 48.4938 4.31538 46.1227 6.58352L29.9933 22.013L13.8167 6.53837C8.7817 1.7219 6.26421 -0.686339 3.99465 0.1707Z"
              fill="white"/>
          <g opacity="0.9">
            <path
                d="M59.2016 11.4548C58.5831 4.50341 58.2739 1.02774 56.0043 0.1707C53.7348 -0.686339 51.2173 1.7219 46.1823 6.53837L12.8903 38.3856C10.6516 40.5272 9.53218 41.598 9.08226 43.0103C8.63235 44.4227 8.9253 45.9462 9.51119 48.9932L9.84052 50.7059C10.9119 56.2776 11.4476 59.0635 13.476 59.8243C15.5044 60.5852 17.7309 58.8354 22.1839 55.3358L53.8284 30.4664C57.0589 27.9276 58.6741 26.6581 59.4536 24.8523C60.233 23.0466 60.0506 20.9964 59.6858 16.8961L59.2016 11.4548Z"
                fill="url(#paint0_linear_0_4736)"/>
            <path
                d="M59.2016 11.4548C58.5831 4.50341 58.2739 1.02774 56.0043 0.1707C53.7348 -0.686339 51.2173 1.7219 46.1823 6.53837L12.8903 38.3856C10.6516 40.5272 9.53218 41.598 9.08226 43.0103C8.63235 44.4227 8.9253 45.9462 9.51119 48.9932L9.84052 50.7059C10.9119 56.2776 11.4476 59.0635 13.476 59.8243C15.5044 60.5852 17.7309 58.8354 22.1839 55.3358L53.8284 30.4664C57.0589 27.9276 58.6741 26.6581 59.4536 24.8523C60.233 23.0466 60.0506 20.9964 59.6858 16.8961L59.2016 11.4548Z"
                fill="black" fill-opacity="0.1"/>
          </g>
          <g opacity="0.9">
            <path
                d="M0.797342 11.4548C1.41584 4.50341 1.72508 1.02774 3.99465 0.1707C6.26421 -0.686339 8.7817 1.7219 13.8167 6.53837L47.1087 38.3856C49.3474 40.5272 50.4668 41.598 50.9167 43.0103C51.3666 44.4227 51.0737 45.9462 50.4878 48.9932L50.1585 50.7059C49.0871 56.2776 48.5514 59.0635 46.523 59.8243C44.4946 60.5852 42.2681 58.8354 37.8151 55.3358L6.17062 30.4664C2.94011 27.9276 1.32485 26.6581 0.545405 24.8523C-0.234036 23.0466 -0.0516228 20.9964 0.313203 16.8961L0.797342 11.4548Z"
                fill="url(#paint1_linear_0_4736)"/>
            <path
                d="M0.797342 11.4548C1.41584 4.50341 1.72508 1.02774 3.99465 0.1707C6.26421 -0.686339 8.7817 1.7219 13.8167 6.53837L47.1087 38.3856C49.3474 40.5272 50.4668 41.598 50.9167 43.0103C51.3666 44.4227 51.0737 45.9462 50.4878 48.9932L50.1585 50.7059C49.0871 56.2776 48.5514 59.0635 46.523 59.8243C44.4946 60.5852 42.2681 58.8354 37.8151 55.3358L6.17062 30.4664C2.94011 27.9276 1.32485 26.6581 0.545405 24.8523C-0.234036 23.0466 -0.0516228 20.9964 0.313203 16.8961L0.797342 11.4548Z"
                fill="url(#paint2_linear_0_4736)" fill-opacity="0.2"/>
          </g>
          <path opacity="0.6"
                d="M3.99465 0.215866C1.72508 1.0729 1.41584 4.54858 0.797341 11.4999L0.313203 16.9412C-0.0516228 21.0416 -0.234036 23.0917 0.545405 24.8975C1.32485 26.7033 2.9401 27.9727 6.17062 30.5116L29.9697 49.2153V22.0356L13.8167 6.58354C8.7817 1.76706 6.26421 -0.641173 3.99465 0.215866Z"
                fill="url(#paint3_linear_0_4736)"/>
          <g opacity="0.8">
            <path
                d="M55.9448 0.215866C58.2144 1.0729 58.5236 4.54858 59.1421 11.4999L59.6263 16.9412C59.9911 21.0416 60.1735 23.0917 59.394 24.8975C58.6146 26.7033 56.9993 27.9727 53.7688 30.5116L29.9697 49.2153V22.0356L46.1228 6.58354C51.1578 1.76706 53.6752 -0.641173 55.9448 0.215866Z"
                fill="url(#paint4_linear_0_4736)"/>
            <path
                d="M55.9448 0.215866C58.2144 1.0729 58.5236 4.54858 59.1421 11.4999L59.6263 16.9412C59.9911 21.0416 60.1735 23.0917 59.394 24.8975C58.6146 26.7033 56.9993 27.9727 53.7688 30.5116L29.9697 49.2153V22.0356L46.1228 6.58354C51.1578 1.76706 53.6752 -0.641173 55.9448 0.215866Z"
                fill="url(#paint5_linear_0_4736)" fill-opacity="0.2"/>
          </g>
          <path
              d="M73.7133 50.6334C73.4022 50.6334 73.1222 50.5245 72.8733 50.3067C72.6244 50.0889 72.5 49.7934 72.5 49.42V19.18C72.5 18.8378 72.6244 18.5578 72.8733 18.34C73.1222 18.0911 73.4022 17.9667 73.7133 17.9667H81.32C81.6622 17.9667 81.9422 18.0911 82.16 18.34C82.4089 18.5578 82.5333 18.8378 82.5333 19.18V29.7267H92.0534V19.18C92.0534 18.8378 92.1622 18.5578 92.38 18.34C92.6289 18.0911 92.9245 17.9667 93.2667 17.9667H100.873C101.216 17.9667 101.496 18.0911 101.713 18.34C101.962 18.5578 102.087 18.8378 102.087 19.18V49.42C102.087 49.7623 101.962 50.0578 101.713 50.3067C101.496 50.5245 101.216 50.6334 100.873 50.6334H93.2667C92.9245 50.6334 92.6289 50.5245 92.38 50.3067C92.1622 50.0578 92.0534 49.7623 92.0534 49.42V38.5934H82.5333V49.42C82.5333 49.7623 82.4089 50.0578 82.16 50.3067C81.9422 50.5245 81.6622 50.6334 81.32 50.6334H73.7133Z"
              fill="white"/>
          <path
              d="M119.67 51.1C116.684 51.1 114.086 50.6334 111.877 49.7C109.699 48.7667 107.972 47.3667 106.697 45.5C105.452 43.6023 104.784 41.2534 104.69 38.4534C104.659 37.1467 104.644 35.8089 104.644 34.44C104.644 33.04 104.659 31.6556 104.69 30.2867C104.784 27.5178 105.452 25.1845 106.697 23.2867C107.972 21.3889 109.715 19.9578 111.924 18.9933C114.132 17.9978 116.715 17.5 119.67 17.5C122.595 17.5 125.161 17.9978 127.37 18.9933C129.579 19.9578 131.321 21.3889 132.597 23.2867C133.872 25.1845 134.541 27.5178 134.604 30.2867C134.666 31.6556 134.697 33.04 134.697 34.44C134.697 35.8089 134.666 37.1467 134.604 38.4534C134.51 41.2534 133.826 43.6023 132.55 45.5C131.306 47.3667 129.579 48.7667 127.37 49.7C125.192 50.6334 122.626 51.1 119.67 51.1ZM119.67 43.1667C120.977 43.1667 122.05 42.7778 122.89 42C123.73 41.1911 124.166 39.9156 124.197 38.1734C124.259 36.8356 124.29 35.5445 124.29 34.3C124.29 33.0245 124.259 31.7333 124.197 30.4267C124.166 29.2756 123.948 28.3267 123.544 27.58C123.17 26.8333 122.641 26.2889 121.957 25.9467C121.304 25.6045 120.541 25.4333 119.67 25.4333C118.799 25.4333 118.021 25.6045 117.337 25.9467C116.652 26.2889 116.108 26.8333 115.704 27.58C115.33 28.3267 115.128 29.2756 115.097 30.4267C115.066 31.7333 115.05 33.0245 115.05 34.3C115.05 35.5445 115.066 36.8356 115.097 38.1734C115.159 39.9156 115.595 41.1911 116.404 42C117.244 42.7778 118.332 43.1667 119.67 43.1667Z"
              fill="white"/>
          <path
              d="M138.363 50.6334C138.052 50.6334 137.772 50.5245 137.523 50.3067C137.274 50.0578 137.15 49.7623 137.15 49.42V19.18C137.15 18.8378 137.274 18.5578 137.523 18.34C137.772 18.0911 138.052 17.9667 138.363 17.9667H146.11C146.452 17.9667 146.748 18.0911 146.996 18.34C147.245 18.5578 147.37 18.8378 147.37 19.18V42.3267H160.857C161.199 42.3267 161.494 42.4511 161.743 42.7C161.992 42.9178 162.117 43.1978 162.117 43.54V49.42C162.117 49.7623 161.992 50.0578 161.743 50.3067C161.494 50.5245 161.199 50.6334 160.857 50.6334H138.363Z"
              fill="white"/>
          <path
              d="M165.492 50.6334C165.181 50.6334 164.901 50.5245 164.652 50.3067C164.403 50.0578 164.278 49.7623 164.278 49.42V19.18C164.278 18.8378 164.403 18.5578 164.652 18.34C164.901 18.0911 165.181 17.9667 165.492 17.9667H178.279C181.296 17.9667 183.91 18.4644 186.119 19.46C188.327 20.4244 190.07 21.8556 191.345 23.7533C192.621 25.6511 193.305 27.9845 193.399 30.7533C193.461 32.0911 193.492 33.2734 193.492 34.3C193.492 35.3267 193.461 36.4934 193.399 37.8C193.274 40.7245 192.605 43.1356 191.392 45.0334C190.21 46.9311 188.53 48.3467 186.352 49.28C184.174 50.1823 181.561 50.6334 178.512 50.6334H165.492ZM174.312 42.7H178.279C179.305 42.7 180.176 42.5289 180.892 42.1867C181.607 41.8445 182.167 41.3156 182.572 40.6C182.976 39.8534 183.194 38.9045 183.225 37.7534C183.256 36.8511 183.272 36.0422 183.272 35.3267C183.303 34.6111 183.303 33.8956 183.272 33.18C183.272 32.4645 183.256 31.6711 183.225 30.8C183.163 29.0889 182.665 27.8445 181.732 27.0667C180.83 26.2889 179.601 25.9 178.045 25.9H174.312V42.7Z"
              fill="white"/>
          <path
              d="M210.69 51.1C207.797 51.1 205.246 50.6489 203.037 49.7467C200.859 48.8445 199.148 47.4445 197.904 45.5467C196.69 43.6178 196.084 41.1911 196.084 38.2667V19.18C196.084 18.8378 196.208 18.5578 196.457 18.34C196.706 18.0911 196.986 17.9667 197.297 17.9667H204.904C205.246 17.9667 205.526 18.0911 205.744 18.34C205.993 18.5578 206.117 18.8378 206.117 19.18V38.1267C206.117 39.7134 206.506 40.9111 207.284 41.72C208.061 42.5289 209.181 42.9334 210.644 42.9334C212.106 42.9334 213.21 42.5289 213.957 41.72C214.735 40.9111 215.124 39.7134 215.124 38.1267V19.18C215.124 18.8378 215.248 18.5578 215.497 18.34C215.746 18.0911 216.041 17.9667 216.384 17.9667H223.944C224.286 17.9667 224.566 18.0911 224.784 18.34C225.033 18.5578 225.157 18.8378 225.157 19.18V38.2667C225.157 41.1911 224.55 43.6178 223.337 45.5467C222.124 47.4445 220.428 48.8445 218.25 49.7467C216.073 50.6489 213.553 51.1 210.69 51.1Z"
              fill="white"/>
          <path
              d="M228.859 50.6334C228.548 50.6334 228.268 50.5245 228.019 50.3067C227.77 50.0578 227.646 49.7623 227.646 49.42V43.7734C227.646 43.3378 227.723 42.98 227.879 42.7C228.066 42.42 228.221 42.2178 228.346 42.0934L242.439 26.18H229.326C228.983 26.18 228.688 26.0711 228.439 25.8533C228.221 25.6045 228.112 25.3089 228.112 24.9667V19.18C228.112 18.8378 228.221 18.5578 228.439 18.34C228.688 18.0911 228.983 17.9667 229.326 17.9667H253.872C254.215 17.9667 254.51 18.0911 254.759 18.34C255.008 18.5578 255.132 18.8378 255.132 19.18V24.7333C255.132 25.0756 255.07 25.3867 254.946 25.6667C254.821 25.9156 254.681 26.1333 254.526 26.32L241.039 42.3267H254.479C254.821 42.3267 255.101 42.4511 255.319 42.7C255.568 42.9178 255.692 43.1978 255.692 43.54V49.42C255.692 49.7623 255.568 50.0578 255.319 50.3067C255.101 50.5245 254.821 50.6334 254.479 50.6334H228.859Z"
              fill="white"/>
          <defs>
            <linearGradient id="paint0_linear_0_4736" x1="4.59068" y1="-4.55348" x2="73.5056" y2="44.9568"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#1C92FF"/>
              <stop offset="1" stop-color="#CB73FC"/>
            </linearGradient>
            <linearGradient id="paint1_linear_0_4736" x1="55.4083" y1="-4.55348" x2="-13.5066" y2="44.9568"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#1C92FF"/>
              <stop offset="1" stop-color="#CB73FC"/>
            </linearGradient>
            <linearGradient id="paint2_linear_0_4736" x1="39.6212" y1="30.7833" x2="24.4364" y2="45.077"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="white"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint3_linear_0_4736" x1="41.3102" y1="72.9574" x2="3.97074" y2="0.865124"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.7"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint4_linear_0_4736" x1="27.829" y1="0.0451638" x2="73.2213" y2="25.8678"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#1C92FF"/>
              <stop offset="1" stop-color="#CB73FC"/>
            </linearGradient>
            <linearGradient id="paint5_linear_0_4736" x1="29.9697" y1="49.1778" x2="54.3987" y2="1.31621"
                            gradientUnits="userSpaceOnUse">
              <stop/>
              <stop offset="1" stop-opacity="0"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="head-text">
        Official channel <br>
        of cryptowallet <br>
        Holduz
      </div>
      <a href="https://t.me/+EhvRNaAeapMzZmVi" class="button button-desktop">
        Open in Telegram
      </a>
    </div>
    <div class="prototype">
      <prototype-svg/>
    </div>
  </div>
  <a href="https://t.me/+EhvRNaAeapMzZmVi" class="button button-mobile">
    Open in Telegram
  </a>
</template>

<style lang="scss">
.main-popup {
  box-shadow: 0px 4px 250px rgba(148, 171, 255, 0.12);
  width: 100%;
  max-width: 800px;
  height: 480px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  box-sizing: border-box;
  position: relative;
  background-image: url("../assets/img/bg-desktop.svg");
  background-size: cover; /* This property makes the background image cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  border-radius: 48px;

  @media (max-width: 800px) {
    border-radius: 40px;
    max-width: 320px;
    flex-direction: column;
    height: 480px;
    gap: 0;
    background-image: none;
  box-shadow: 0px 4px 200px rgba(148, 171, 255, 0.12);
  }

  .bg-mob {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media (max-width: 800px) {
      display: block;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 380px;
    width: 100%;
    padding: 56px 0 60px 60px;
    box-sizing: border-box;

    @media (max-width: 800px) {
      padding: 32px 0 24px;
      justify-content: center;
      align-items: center;
      max-width: 100%;
    }

    .logo {
      display: flex;
      @media (max-width: 800px) {
        justify-content: center;

        svg {
          max-width: 204.55px;
          height: 48px;
        }
      }
    }

    .head-text {
      max-width: 320px;
      width: 100%;
      color: #FFF;
      font-family: 'Inter', sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      letter-spacing: -0.36px;
      margin-top: 48px;
      margin-bottom: 52px;

      @media (max-width: 800px) {
        color: #FFF;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 38px; /* 118.75% */
        letter-spacing: -0.32px;
        margin-bottom: 0;
        margin-top: 32px;
      }
    }
  }

  .prototype {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.button {
  border-radius: 14px;
  background: var(--main, linear-gradient(227deg, #1C92FF -3.69%, #CB73FC 100%));
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  padding: 18px 0;
  cursor: pointer;
  transition: all .3s ease-in-out;
  max-width: 320px;
  width: 100%;

  @media (min-width: 1080px) {
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%), linear-gradient(227deg, #1C92FF -3.69%, #CB73FC 100%);
    }
  }

  &-desktop {
    display: block;

    @media (max-width: 800px) {
      display: none;
    }
  }

  &-mobile {
    display: none;

    @media (max-width: 800px) {
      display: block;
    }
  }
}
</style>
