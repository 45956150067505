<template>
  <div class="app">
    <main-popup/>
  </div>
</template>

<script>

import MainPopup from "./components/MainPopup.vue";

export default {
  name: 'App',
  components: {
    MainPopup
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #101018;
}

a {
  text-decoration: none;
}

.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  padding: 0;
  margin: 0;

  @media (max-width: 800px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
}
</style>
